import React, { useState } from "react";

import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";

import Search from "../search/Search";
import LogoImage from "../../assets/img56.svg";
import * as C from "./constants";

const Header = () => {
  const isMobile = useMediaQuery("(max-width:767px)");

  const [openNav, setOpenNav] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => setSearchTerm(e.target.value);

  return (
    <>
      <AppBar
        position={isMobile ? "relative" : "fixed"}
        sx={(theme) => ({
          height: {
            xs: C.HEADER.H_MOBILE + "vh",
            md: C.HEADER.H_DESKTOP
          },
          backgroundColor: "secondary.main",
          zIndex: 1,
          transition: theme.transitions.create(["height"], {
            duration: theme.transitions.duration.shorter
          })
        })}
      >
        <Container maxWidth="lg" sx={{ height: "100%", display: "flex" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <Search
              id="search"
              label="Search"
              value={searchTerm}
              onInputChange={handleSearch}
            />
            <Box display="flex" height="100%" width="100%">
              <img src={LogoImage} alt="Logo" />
            </Box>

            <Box sx={{ display: { xs: "none", sm: "flex" } }}>
              <Button color="primary" size="large">
                Home
              </Button>
              <Button color="primary" size="large">
                About
              </Button>
              <Button color="primary" size="large">
                Contact
              </Button>
              <Button color="primary" size="large">
                Login
              </Button>
            </Box>
            <Box
              sx={{
                display: { xs: "flex", sm: "none" },
                justifyContent: "flex-end",
                width: "100%"
              }}
            >
              <IconButton
                onClick={() => setOpenNav(!openNav)}
                size="large"
                edge="start"
                color="primary"
                aria-label="menu"
                sx={{ pr: "0px" }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Drawer
              open={openNav}
              PaperProps={{
                sx: {
                  width: "100%"
                }
              }}
            >
              <Stack
                component="nav"
                spacing={0.5}
                sx={{
                  px: 2,
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row"
                }}
              >
                Test
                <IconButton
                  onClick={() => setOpenNav(false)}
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ pr: "0px" }}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
            </Drawer>
          </Box>
        </Container>
      </AppBar>
      {!isMobile && (
        <Box
          sx={{
            height: { xs: C.HEADER.H_MOBILE, md: C.HEADER.H_DESKTOP }
          }}
        />
      )}
    </>
  );
};

export default Header;
