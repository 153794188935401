import React, { useState, useRef } from "react";
import "./Drawer.css";

const SwipeableDrawer = () => {
  const [drawerPosition, setDrawerPosition] = useState(5); // Initial position at 5vh
  const startY = useRef(null);
  const initialPosition = useRef(drawerPosition);

  const positions = [5, 50, 75]; // Snap positions in vh

  const handleTouchStart = (e) => {
    startY.current = e.touches[0].clientY;
    initialPosition.current = drawerPosition; // Store the initial position
  };

  const handleTouchMove = (e) => {
    if (!startY.current) return; // Ensure there's a touch start
    const touchY = e.touches[0].clientY;
    const delta = ((touchY - startY.current) / window.innerHeight) * 100; // Calculate the swipe distance
    const newPosition = Math.min(
      100,
      Math.max(0, initialPosition.current + delta)
    );
    setDrawerPosition(newPosition); // Update the drawer position
  };

  const handleTouchEnd = () => {
    startY.current = null; // Reset starting touch
    const closestPosition = positions.reduce((prev, curr) =>
      Math.abs(curr - drawerPosition) < Math.abs(prev - drawerPosition)
        ? curr
        : prev
    );
    setDrawerPosition(closestPosition); // Snap to closest position
  };

  return (
    <div
      className="drawer"
      style={{ transform: `translateY(${100 - drawerPosition}vh)` }} // Correct the transformation logic
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      Your drawer content goes here.
    </div>
  );
};

export default SwipeableDrawer;
