import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CssBaseline, Box } from "@mui/material";

import Header from "./components/header";
import Main from "./components/main";
import Footer from "./components/footer";
import SwipeableDrawer from "./components/swipeableDrawer";
import ThemeProvider from "./theme";

const App = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  return (
    <ThemeProvider>
      <Box
        display={isMobile ? "block" : "flex"}
        flexDirection="column"
        height="100vh"
      >
        <CssBaseline />
        <Header />
        <Main />
        <Footer />
        <SwipeableDrawer />
      </Box>
    </ThemeProvider>
  );
};

export default App;
