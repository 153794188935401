import React from "react";
import { Box, Container, Grid, Typography, Link, Divider } from "@mui/material";

const FatFooter = () => {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: "#1f1f1f",
        color: "white",
        py: 6,
        width: "100%",
        maxWidth: "100%",
        position: { xs: "relative" },
        bottom: 0,
        zIndex: 1000,
        "@media (max-width: 768px)": {
          display: "none",
        },
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="common" gutterBottom>
              About Us
            </Typography>
            <Typography variant="body2">
              We are ReactONI company, dedicated to providing the best software
              development service to our customers.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="common" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body2">
              123 Main Street, Anytown, USA 12345
            </Typography>
            <Typography variant="body2">Email: info@example.com</Typography>
            <Typography variant="body2">Phone: +1 234 567 8901</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="common" gutterBottom>
              Follow Us
            </Typography>
            <Link href="https://www.facebook.com/" color="inherit">
              {" "}
              Facebook
            </Link>
            <br />
            <Link href="https://www.instagram.com/" color="inherit">
              {" "}
              Instagram
            </Link>
            <br />
            <Link href="https://www.twitter.com/" color="inherit">
              {" "}
              Twitter
            </Link>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Divider />
          <Box mt={3}>
            <Typography variant="body2" align="center">
              ©{new Date().getFullYear()} ReactONI. All rights reserved.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default FatFooter;
