import React, { memo } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import useMediaQuery from "@mui/material/useMediaQuery";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Image1 from "../../assets/imageReact.png";

const Main = () => {
  const isMobile = useMediaQuery("(max-width:767px)");

  const styles = {
    flexGrow: 1,
    display: isMobile ? "block" : "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflow: "auto",
    height: "100%"
  };

  return (
    <Box sx={styles}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: { xs: "center", sm: "flex-end" },
            alignItems: "center",
            position: "relative",
            width: "100%",
            flexDirection: { xs: "column", lg: "row" },
            background:
              "linear-gradient(to bottom, rgba(21, 89, 130, 0) 0%, rgba(21, 89, 130, 0.5) 0%, rgba(21, 89, 130, 0.8) 80%), radial-gradient(ellipse at bottom, rgb(21, 89, 130) 0%, rgba(21, 89, 130, 0) 100%)",
            p: { xs: 2, sm: 3, md: 4 }
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", lg: "column" },
              alignItems: { xs: "center", sm: "flex-start" },
              py: { xs: 5 }
            }}
          >
            <Typography
              component="h2"
              sx={{
                fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
                fontWeight: "bold",
                color: "common.white",
                textAlign: { xs: "center", sm: "left" },
                maxWidth: "80%"
              }}
            >
              Web Development
              <Typography
                component="p"
                sx={{
                  fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
                  fontWeight: "bold",
                  textAlign: { xs: "center", sm: "left" },
                  color: "#FEB070"
                }}
              >
                ReactJS.
              </Typography>
            </Typography>
            <Typography
              component="p"
              sx={{
                fontSize: { xs: "1.5rem", sm: "2rem", md: "1.25rem" },
                fontWeight: "bold",
                color: "common.white",
                textAlign: { xs: "center", sm: "left" },
                pt: 4
              }}
            >
              Responsive and dynamic websites and web applications.
            </Typography>
          </Box>
          <Box component="img" src={Image1} alt="company" />
        </Box>
      </Box>
      <Grid container spacing={2} maxWidth="lg" p={2}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((card) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={card}
            sx={(theme) => ({
              [theme.breakpoints.only("xs")]: {
                paddingRight: "0 !important"
              }
            })}
          >
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" component="div">
                  Card {card}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  This is a sample card with some content. You can replace this
                  with your own content.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Learn More</Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default memo(Main);
