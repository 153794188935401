import React from "react";

const Search = ({ id, label, value, type = "text", onInputChange }) => (
  <>
    <label htmlFor={id} style={{ color: "black" }}>
      {label}
    </label>
    &nbsp;
    <input id={id} type={type} value={value} onChange={onInputChange} />
  </>
);

export default Search;
